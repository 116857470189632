import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de fins a 25 cm de diàmetre primer convex per passar a pla. És de color crema a crema groguenc i presenta un marge enrotllat i moltes vegades ondulat. Té nombroses làmines de blanquinoses a color crema groguenc i quasi decurrents. El peu, d’un color més clar que el capell s’engreixa a la base. Les espores són blanques en massa, ovoides amb berrugues, de 6-8 x 4-5,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      